import { Injectable, Signal, WritableSignal, signal } from '@angular/core';

const activeOrganizationStorageKey = 'activeOrganizationId';

@Injectable({ providedIn: 'root' })
export class ActiveOrganizationService {
  private readonly _activeOrganizationId: WritableSignal<string | null> = signal<string | null>(
    localStorage.getItem(activeOrganizationStorageKey) ?? sessionStorage.getItem(activeOrganizationStorageKey),
  );

  /**
   * Returns the id of the active organization.
   */
  readonly activeOrganizationId: Signal<string | null> = this._activeOrganizationId.asReadonly();

  /**
   * Returns whether the active organization is remembered between logins.
   */
  get isActiveOrganizationRemembered(): boolean {
    return localStorage.getItem(activeOrganizationStorageKey) != null;
  }

  /**
   * Sets the active organization id.
   * @param activeOrganizationId The new active organization id.
   * @param remember If `true` the active organization id is preserved during logins.
   * @param referrer The URL to navigate to.
   */
  setActiveOrganizationId(activeOrganizationId: string, remember?: boolean, referrer?: string): void {
    remember ??= this.isActiveOrganizationRemembered;

    const [writeStorage, removeStorage] = remember ? [localStorage, sessionStorage] : [sessionStorage, localStorage];

    this._activeOrganizationId.set(activeOrganizationId);

    writeStorage.setItem(activeOrganizationStorageKey, activeOrganizationId);
    removeStorage.removeItem(activeOrganizationStorageKey);

    if (referrer) {
      self.location.assign(referrer);
    }
  }

  /**
   * Resets the active organization id on logout if it is not remembered.
   */
  resetActiveOrganizationIdOnLogout(): void {
    sessionStorage.removeItem(activeOrganizationStorageKey);
  }
}
